import React from 'react';

const Footer: React.FC = () => {

    return (
        <div className="px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
            <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">
                <div className="sm:col-span-2">
                    <a
                        href="/"
                        aria-label="Go home"
                        title="Soupe15"
                        className="inline-flex items-center"
                    >
                        <svg className="logo w-32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 1000"
                             fill="currentColor">
                            <g>
                                <path fill="currentColor" d="M929.548,601.31c-3.199-3.123-5.616-6.866-7.263-11.214c-1.752-1.155-3.346-2.403-4.737-3.771
		c-6.592-6.434-9.889-15.493-9.889-27.108c0-4.08,0.391-8.217,1.178-13.217h42.38c-0.157,1-0.235,2.232-0.235,3.326
		c0,4.084,1.608,7.397,4.827,9.906c0.877,0.686,1.917,1.269,3.093,1.768h4.315c-0.061,0.388-0.106,0.815-0.144,1.25
		c2.412,0.494,5.194,0.756,8.392,0.756c7.218,0,13.302-2.311,18.246-6.94c4.944-4.628,7.417-10.394,7.417-17.303
		c0-2.273-0.211-4.373-0.594-6.332c-1.796-0.622-3.716-0.943-5.771-0.943c-9.264,0-16.404,2.132-21.425,6.212l-2.59,2.301h-40.261
		l2.631-15h-14.631l17.188-98h62.979C959.188,185,751.239,0.153,500,0.153c-252.323,0-460.991,186.829-495.112,429.744
		c10.312-3.18,22.663-4.814,37.067-4.814c18.519,0,32.527,3.477,42.026,10.459c5.457,4.014,9.337,8.925,11.659,14.725
		c0.111,0.08,0.23,0.155,0.341,0.236c9.495,6.985,14.245,16.659,14.245,29.057c0,4.555-0.589,8.87-1.766,13.026
		c-1.177,4.162-2.395,7.025-3.65,8.75L102.928,504H61.68c6.792,2.72,12.624,6.88,17.476,12.511c2.187,2.544,3.919,5.402,5.21,8.565
		c2.44,1.881,4.707,4.021,6.79,6.438c5.334,6.203,8.005,14.247,8.005,24.134c0,19.936-5.968,34.728-17.894,44.382
		c-11.93,9.651-30.294,14.481-55.093,14.481c-1.454,0-2.875-0.018-4.274-0.048c-1.005-4.979-1.913-9.992-2.768-15.023
		c-1.625,0.045-3.274,0.071-4.958,0.071c-1.454,0-2.875-0.018-4.274-0.048C56.019,827.953,257.915,1000,500,1000
		c236.842,0,435.209-164.678,486.878-385.774c-2.632,0.178-5.333,0.276-8.121,0.276C954.896,614.502,938.495,610.102,929.548,601.31
		z M250.078,550.231c-3.767,21.82-11.93,37.988-24.487,48.503c-12.559,10.517-30.217,15.775-52.975,15.775
		c-22.759,0-38.612-5.259-47.558-15.775c-2.269-2.675-4.115-5.73-5.543-9.16c-2.442-1.727-4.606-3.664-6.457-5.84
		c-6.122-7.219-9.182-17.188-9.182-29.9c0-5.65,0.625-11.852,1.882-18.603L114,488.145c7.375-42.063,33.115-63.099,77.224-63.099
		c27.915,0,45.52,8.433,52.834,25.274c10.603,7.704,15.908,19.738,15.908,36.108c0,5.18-0.552,10.754-1.649,16.717L250.078,550.231z
		 M380.157,598.861c-12.479,10.439-30.101,15.668-52.856,15.668c-22.762,0-38.537-5.24-47.325-15.757
		c-2.357-2.709-4.271-5.8-5.743-9.276c-2.371-1.707-4.467-3.619-6.257-5.762c-6.28-7.219-9.418-17.188-9.418-29.9
		c0-5.65,0.625-11.698,1.884-18.449L279.512,427h42.38l-2.663,15h14.663l-19.071,107.453c-0.629,3.927-0.941,7.862-0.941,12.101
		c0,0.235,0.021,0.453,0.026,0.684c2.269,0.532,4.839,0.815,7.75,0.815c7.848,0,14.127-2.055,18.835-6.139
		c4.709-4.08,8.006-11.471,9.889-22.46L369.451,427h42.38l-2.639,15h14.639l-19.07,108.39
		C400.835,572.368,392.635,588.425,380.157,598.861z M542.73,547.229C531.269,555.628,517.302,560,500.82,560h-28.018l-9.183,51
		h-42.38l2.654-15h-14.654l29.902-169h71.81c17.735,0,30.371,3.931,37.905,12.089c2.594,2.966,4.567,6.305,5.937,10.022
		c2.29,1.439,4.319,3.09,6.063,4.978c5.492,6.281,8.241,14.133,8.241,23.864c0,3.295-0.315,6.794-0.941,10.403l-4.708,26.648
		C561.094,528.033,554.185,538.834,542.73,547.229z M708.242,478h-75.813l-2.877,17h48.089l-2.672,15h14.672l-6.592,37h-62.628
		l-2.093,12h61.196l-2.672,15h14.672l-6.591,37H566.739l2.654-15h-14.654l29.901-169h118.192l-2.746,15h14.746L708.242,478z
		 M845.966,611l2.659-15h-14.659l14.541-82.032c-1.045,0.64-2.123,1.277-3.246,1.909c-10.046,5.651-20.564,8.495-31.55,8.495
		l-1.239-16.008c-3.536,0.623-7.122,0.943-10.761,0.943L798.978,474h-20.345l2.334-15h-14.334l8.712-56h26.604l-3.027,15h15.027
		l-10.412,51.596c12.925-0.864,25.11-5.184,36.551-13.207C852.957,447.366,860.885,437,863.867,427h42.381l-2.633,15h14.633
		l-29.665,169H845.966z"/>
                                <path fill="currentColor" d="M59.614,503.208c0.597,0.208,1.175,0.445,1.758,0.674c3.367-3.011,5.062-7.948,5.062-13.377
		c0-0.519-0.029-1.014-0.063-1.505H49.254c3.317-2.884,5.026-7.621,5.155-12.866c-2.33-0.506-4.98-0.772-7.989-0.772
		c-16.7,0-25.086,5.282-25.182,15.831c4.479,2.154,9.615,3.925,15.42,5.299C44.27,498.298,51.921,500.541,59.614,503.208z"/>
                                <path fill="currentColor" d="M9.229,476.332c0,3.595,1.215,6.698,3.636,9.302c0.4-6.897,0.939-13.755,1.616-20.573
		C10.993,467.718,9.229,471.469,9.229,476.332z"/>
                                <path fill="currentColor" d="M12.557,538.625c-0.173-3.716-0.304-7.442-0.397-11.179c-3.868-1.183-7.735-2.459-11.603-3.82
		c0.582,12.511,1.625,24.9,3.11,37.154c3.875,2.267,9.365,3.413,16.51,3.413c15.598,0,23.404-4.854,23.424-14.564
		c-4.482-2.315-9.621-4.312-15.426-5.99C22.967,542.138,17.762,540.457,12.557,538.625z"/>
                                <path fill="currentColor" d="M168.145,504.087l-8.005,45.203c-0.63,3.927-0.943,8.006-0.943,12.244c0,0.228,0.02,0.438,0.025,0.66
		c2.269,0.535,4.84,0.819,7.752,0.819c7.847,0,14.126-2.036,18.835-6.12c4.708-4.08,8.005-11.614,9.889-22.604l8.005-45.203
		c0.471-3.138,0.707-5.963,0.707-8.476c0-1.138-0.07-2.207-0.173-3.243c-2.108-0.542-4.476-0.829-7.133-0.829
		C180.935,476.857,171.281,486.039,168.145,504.087z"/>
                                <path fill="currentColor" d="M486.929,478l-5.18,30h6.365c12.867,0,20.326-5.768,22.366-17.226l1.649-9.673
		c0.172-0.781,0.293-1.723,0.37-2.795c-1.336-0.201-2.794-0.306-4.38-0.306H486.929z"/>
                                <path fill="currentColor" d="M976.476,482.654c4.646-0.671,9.917-1.017,15.826-1.017c2.54,0,4.996,0.276,7.373,0.564
		c-0.038-1.074-0.099-2.133-0.144-3.202h-22.424L976.476,482.654z"/>
                                <path fill="currentColor" d="M54.434,475.505c0,0.212-0.02,0.418-0.025,0.629c7.478,1.624,11.471,5.87,11.961,12.866h24.558l1.882-2.662
		c1.255-1.726,2.473-4.505,3.65-8.667c1.177-4.156,1.766-8.507,1.766-13.061c0-5.273-0.868-10.05-2.586-14.342
		c-9.492-6.821-23.381-10.232-41.685-10.232c-14.404,0-26.756,1.6-37.067,4.78c-0.942,6.705-1.734,13.458-2.408,20.246
		c4.165-3.173,10.798-4.779,19.94-4.779C47.76,460.282,54.434,465.304,54.434,475.505z"/>
                                <path fill="currentColor"
                                      d="M61.254,504h0.426c-0.102-0.041-0.206-0.078-0.308-0.118C61.331,503.919,61.296,503.964,61.254,504z"/>
                                <path fill="currentColor" d="M87.161,540.646c0-5.833-0.937-11.019-2.795-15.57c-3.815-2.94-8.062-5.234-12.751-6.863
		c-7.693-2.667-15.344-4.904-22.956-6.71c-7.616-1.803-14.089-4.274-19.424-7.416c-5.338-3.138-8.005-7.376-8.005-12.714
		c0-0.062,0.008-0.118,0.009-0.179c-1.398-0.672-2.735-1.379-4.004-2.126c-1.743-1.024-3.195-2.171-4.369-3.433
		C12.302,495.352,12,505.14,12,514.998c0,4.162,0.057,8.311,0.16,12.448c1.338,0.409,2.676,0.809,4.015,1.194
		c7.612,2.199,14.086,4.944,19.423,8.241c5.335,3.296,8.005,7.532,8.005,12.713c0,0.012-0.002,0.023-0.002,0.035
		c1.395,0.721,2.73,1.47,3.997,2.252c5.335,3.296,8.005,7.532,8.005,12.713c0,9.734-7.809,14.599-23.426,14.599
		c-7.145,0-12.634-1.147-16.51-3.413c0.963,7.947,2.134,15.83,3.465,23.658c22.3-0.611,39.015-5.414,50.135-14.41
		C81.193,575.375,87.161,560.583,87.161,540.646z"/>
                                <path fill="currentColor" d="M213.591,583.734c12.557-10.515,20.72-26.683,24.487-48.503l8.239-47.087
		c1.097-5.962,1.649-11.537,1.649-16.717c0-8.101-1.312-15.129-3.908-21.108c-9.425-6.848-23.034-10.274-40.834-10.274
		c-44.109,0-69.85,21.036-77.224,63.099l-8.242,47.087c-1.257,6.751-1.882,12.952-1.882,18.603c0,8.002,1.214,14.914,3.64,20.74
		c9.362,6.614,23.05,9.936,41.101,9.936C183.374,599.51,201.032,594.251,213.591,583.734z M147.196,546.534
		c0-4.238,0.313-8.317,0.943-12.244l8.005-45.203c3.136-18.048,12.791-27.23,28.959-27.548c11.715,0,18.083,5.289,19.133,15.829
		c8.103,2.083,12.173,8.154,12.173,18.243c0,2.513-0.235,5.338-0.707,8.476l-8.005,45.203c-1.884,10.989-5.181,18.523-9.889,22.604
		c-4.709,4.084-10.988,6.12-18.835,6.12c-12.912,0-19.485-5.281-19.752-15.819C151.218,560.304,147.196,555.096,147.196,546.534z"/>
                                <path fill="currentColor" d="M368.157,583.852c12.478-10.437,20.678-26.488,24.604-48.467L409.192,442h-27.741L362.38,549.443
		c-1.883,10.989-5.18,18.446-9.889,22.526c-4.708,4.084-10.988,6.082-18.835,6.082c-12.902,0-19.474-5.291-19.75-15.815
		c-8.005-1.88-12.026-7.064-12.026-15.627c0-4.238,0.312-8.24,0.941-12.167L319.229,442h-27.717l-19.07,108.385
		c-1.259,6.751-1.884,12.875-1.884,18.525c0,7.942,1.225,14.798,3.675,20.586c9.265,6.668,22.943,10.014,41.067,10.014
		C338.056,599.51,355.678,594.291,368.157,583.852z"/>
                                <path fill="currentColor" d="M460.802,545h28.018c16.482,0,30.449-4.372,41.91-12.771c11.454-8.395,18.363-19.176,20.719-32.206
		l4.708-26.673c0.626-3.608,0.941-7.025,0.941-10.32c0-5.136-0.773-9.764-2.305-13.919c-7.605-4.778-18.211-7.11-31.842-7.11h-71.81
		l-27.248,154h27.725L460.802,545z M474.929,463h21.19c6.275,0,10.596,1.629,12.949,4.455c2.354,2.825,3.53,5.631,3.53,7.986
		c0,1.053-0.036,1.999-0.1,2.865c3.951,0.593,6.811,2.032,8.569,4.144c2.355,2.825,3.53,5.521,3.53,7.875s-0.158,4.343-0.47,5.756
		l-1.649,9.692C520.44,517.232,512.98,523,500.113,523h-20.955l2.59-15h-14.59L474.929,463z"/>
                                <polygon fill="currentColor" points="620.429,463 696.242,463 700.087,442 596.641,442 569.394,596 672.934,596 676.852,574
		615.713,574 618.328,559 603.713,559 608.421,532 671.049,532 674.969,510 627.014,510 629.552,495 615.014,495 	"/>
                                <path fill="currentColor"
                                      d="M803.538,469.596c-1.618,0.108-3.245,0.171-4.887,0.171l0.327,4.233h3.671L803.538,469.596z"/>
                                <polygon fill="currentColor"
                                         points="798.923,418 787.346,418 780.968,459 790.649,459 	"/>
                                <path fill="currentColor" d="M903.615,442h-27.748c-2.982,10-10.91,20.372-23.778,29.396c-12.872,9.027-26.687,13.461-41.438,13.461
		l1.82,23.507c7.14-1.26,14.07-3.78,20.789-7.56c10.043-5.65,16.952-11.589,20.719-17.71l-5.473,30.874
		c8.297-5.086,14.127-10.428,17.473-15.864L848.625,596h27.958L903.615,442z"/>
                                <path fill="currentColor" d="M1012,514.998c0-6.029-0.113-12.032-0.325-18.01c-2.377-0.289-4.833-0.351-7.373-0.351
		c-13.892,0-24.292,1.891-31.196,5.501l3.37-19.484c-6.275,0.906-11.403,2.41-15.37,4.484L965.107,464h33.583
		c0.36,4.951,0.629,9.957,0.841,15h11.159c-0.895-13-2.246-25-4.036-37h-62.979l-14.557,83h25.63l2.59-2.301
		c5.021-4.08,12.161-6.303,21.425-6.303c5.181,0,9.535,2.002,13.067,6.004c2.369,2.685,3.924,6.039,4.704,10.03
		c2.73,0.945,5.165,2.602,7.296,5.016c3.531,4.002,5.298,9.435,5.298,16.341c0,6.909-2.473,12.666-7.417,17.294
		c-4.944,4.63-11.028,6.938-18.246,6.938c-7.221,0-12.442-1.257-15.656-3.768c-3.219-2.51-4.827-5.839-4.827-9.923
		c0-0.671,0.033-1.389,0.092-2.078c-1.55-0.316-2.946-0.729-4.172-1.25h-38.065c-0.787,5-1.178,9.137-1.178,13.217
		c0,5.977,0.88,11.269,2.626,15.879c9.514,6.269,24.322,9.414,44.472,9.414c8.704,0,16.648-0.872,23.849-2.597
		c-1.141,5.808-2.387,11.578-3.728,17.312c5.587-0.378,10.833-1.144,15.728-2.316C1008.764,580.556,1012,548.156,1012,514.998z"/>
                            </g>
                        </svg>

                        <span className="ml-4 text-xl font-bold tracking-wide text-gray-800 uppercase">
              Soupe '15
            </span>
                    </a>
                    <div className="mt-6 lg:max-w-sm">
                        <p className="mt-4 text-sm text-gray-800">
                            <i>Imaginative anarchism</i> is a form of anarchism that is based on the idea that the world
                            is a product of our imagination.
                        </p>
                    </div>
                </div>
                <div className="space-y-2 text-sm">
                    <p className="text-base font-bold tracking-wide text-gray-900">
                        Contacts
                    </p>
                    <div className="flex">
                        <p className="mr-1 text-gray-800">Email:</p>
                        <a
                            href="mailto:soupe15.condensed@gmail.com"
                            aria-label="Our email"
                            title="Our email"
                            className="transition-colors duration-300 text-red-accent-400 hover:text-red-800"
                        >
                            soupe15.condensed@gmail.com
                        </a>
                    </div>
                </div>
                <div>
          <span className="text-base font-bold tracking-wide text-gray-900">
            Social
          </span>
                    <div className="flex items-center mt-1 space-x-3">
                        <a
                            href="https://twitter.com/soupe_15"
                            target={"_blank"}
                            className="text-gray-500 transition-colors duration-300 hover:text-red-accent-400"
                        >
                            <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                                <path
                                    d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z"/>
                            </svg>
                        </a>
                        <a
                            href="https://www.instagram.com/soupe15/"
                            target={"_blank"}
                            className="text-gray-500 transition-colors duration-300 hover:text-red-accent-400"
                        >
                            <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
                                <circle cx="15" cy="15" r="4"/>
                                <path
                                    d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z"/>
                            </svg>
                        </a>
                        <a
                            href="https://www.facebook.com/soupe15/"
                            target={"_blank"}
                            className="text-gray-500 transition-colors duration-300 hover:text-red-accent-400"
                        >
                            <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                                <path
                                    d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z"/>
                            </svg>
                        </a>
                    </div>
                    <p className="mt-4 text-sm text-gray-500">
                        Get in touch with us on any of these platforms.
                    </p>
                </div>
            </div>
            <div className="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row">
                <p className="text-sm text-gray-600">
                    © Copyright {new Date().getFullYear()} Soupe '15. All rights reserved.
                </p>
                <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
                    <li>
                        <a
                            href="/"
                            className="text-sm text-gray-600 transition-colors duration-300 hover:text-red-accent-400"
                        >
                            About Us
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Footer;
