// src/Project.tsx
import React from 'react';

interface Props {
    id: number;
    title: string;
    description: string;
    image: string;
    onClick: (id: number, project: any) => void;
    images: string;
    emoji: string;
    created_at: string;
    author: string;
    category: string;
    video: null;
}

const ProjectCard: React.FC<Props> = ({id, title, description, image, onClick, images, emoji, created_at, author, category, video}) => {
    return (
        <div onClick={() => onClick(id, {title, description, image, images, emoji, created_at, author, category, video})}
             className={"cursor-pointer"}>


            <div
                className="relative overflow-hidden transition duration-200 transform rounded shadow-lg hover:-translate-y-2 hover:shadow-2xl">
                <img
                    className="object-cover w-full h-56 md:h-64 xl:h-80"
                    src={image}
                    alt=""
                />
                <div
                    className="absolute inset-0 px-6 py-4 transition-opacity duration-200 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                    <p className="mb-4 text-lg font-bold text-gray-100">{title}</p>
                    <p className="text-sm tracking-wide text-gray-300">
                        {description.replace(/<[^>]*>/g, '').substring(0, 200)}...

                    </p>
                    <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-red-100 uppercase rounded-full bg-red-accent-400">
                        {category}
                    </p>
                </div>
            </div>


        </div>

    );
};

export default ProjectCard;
