// src/App.tsx
import React from 'react';
import {BrowserRouter} from "react-router-dom";
import './styles.css';
import Header from "./Pages/Header";
import Footer from "./Pages/Footer";


export default function App() {
    return (
        <div>
            <Header/>
            <BrowserRouter>

            </BrowserRouter>
            <Footer/>
        </div>

    );
}

