import React from 'react';

const Spinner: React.FC = () => (
    <div className="flex items-center justify-center h-full">
        <div className="spinner rotate-360">
            <span className="dot1" />
            <span className="dot2" />
            <span className="dot3" />
        </div>
    </div>
);

export default Spinner;
