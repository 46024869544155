import React, {useEffect, useState} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import Spinner from "../Spinner";

interface ProjectPageProps {
    video: null;
    id: number;
    title: string;
    description: string;
    image: string;
    images: string;
    emoji: string;
    created_at: string;
    author: string;
}


const ProjectPage: React.FC = () => {
    let {id} = useParams();
    const [project, setProject] = useState<ProjectPageProps | null>(null);
    const location = useLocation();


    useEffect(() => {

        if (location.state === null) {

            const fetchData = async () => {
                const response = await fetch(process.env.REACT_APP_API_URL + 'api/project/' + id);
                const json = await response.json();
                setProject(json.data);
            };

            fetchData();

        } else {
            setProject(location.state.project);
        }
    }, []);

    //json decode images
    let images;
    if (project) {
        images = JSON.parse(project.images);
    }


    if (!project) {
        return <Spinner/>
    }


    return (
        <div>
            <div className="bg-gray-900">
                <div
                    className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
                    <div className="max-w-xl sm:mx-auto lg:max-w-2xl">
                        <div className="flex flex-col mb-16 sm:text-center sm:mb-0">
                            <div className="mb-6 sm:mx-auto">

                                    <span role="img" aria-label="emoji" className={"text-6xl"}>
                                    {project.emoji}
                                    </span>

                            </div>
                            <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
                                <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto">
                <span className="relative inline-block">
                  <span className="relative">{project.title}</span>
                </span>

                                </h2>
                                <p className="text-base prose text-left text-gray-100 md:text-lg"
                                   dangerouslySetInnerHTML={{
                                       __html: project.description
                                   }}
                                >

                                </p>
                                <div className="flex justify-center mt-6">
                                    <div className="flex mr-3">
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                             className="w-6 h-6 text-gray-200 mr-1">
                                          <path fillRule="evenodd"
                                                d="M6.75 2.25A.75.75 0 017.5 3v1.5h9V3A.75.75 0 0118 3v1.5h.75a3 3 0 013 3v11.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V7.5a3 3 0 013-3H6V3a.75.75 0 01.75-.75zm13.5 9a1.5 1.5 0 00-1.5-1.5H5.25a1.5 1.5 0 00-1.5 1.5v7.5a1.5 1.5 0 001.5 1.5h13.5a1.5 1.5 0 001.5-1.5v-7.5z"
                                                clipRule="evenodd"/>
                                        </svg>


                                    </span>
                                        <div className="text-gray-200">
                                            {project.created_at}
                                        </div>
                                    </div>


                                    <div className="flex">
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                                                     fill="currentColor"
                                                     className="w-6 h-6 text-gray-200 mr-1">
                                                  <path fillRule="evenodd"
                                                        d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                                                        clipRule="evenodd"/>
                                                </svg>
                                            </span>

                                        <div className="text-gray-200">{project.author}</div>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>


            {project.video !== null && (
                <div className="pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl lg:pt-20">

                    <div className="aspect-w-16 aspect-h-9">
                        <iframe
                            title={project.title}
                            src={project.video}
                            width="640" height="360"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                        />
                    </div>

                </div>
            )}

            <div
                className="py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl lg:py-20">

                <div className="grid gap-6 row-gap-5 mb-8 lg:grid-cols-2 sm:row-gap-6 sm:grid-cols-1">

                    {images.map((item: string, index: number) => (
                        <div key={index}
                             className="relative overflow-hidden">
                            <img
                                className="object-cover w-full h-auto"
                                src={process.env.REACT_APP_API_URL + '/' + item}
                                alt=""
                            />
                        </div>
                    ))}


                </div>


            </div>


        </div>


    );

}
export default ProjectPage;
