// src/App.tsx
import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import ProjectCard from './ProjectCard';
import './styles.css';
import Spinner from "./Spinner";


interface ProjectData {
    id: number;
    title: string;
    description: string;
    image: string;
    images: string;
    emoji: string;
    created_at: string;
    author: string;
    category: string;
    video: null;
}


const Projects: React.FC = () => {
    const [projects, setProjects] = useState<ProjectData[]>([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();


    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + 'api/projects')
            .then((response) => response.json())
            .then(json => {
                setProjects(json.data);
                setLoading(false);
            });
    }, []);

    const handleProjectClick = (id: number, project: ProjectData) => {
        navigate(`/project/${id}`, {state: {project}});
    };


    return (
        <div>
            {loading ? (
                <Spinner/>
            ) : (


                <div
                    className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">

                    <div className="flex flex-col mb-6 lg:justify-end lg:flex-row md:mb-8">
                        {/*        <h2 className="max-w-lg mb-5 font-sans text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none md:mb-6 group">*/}
                        {/*<span className="inline-block mb-1 sm:mb-4">*/}
                        {/*Unleashing the power of imagination,*/}
                        {/*<br className="hidden md:block"/>*/}
                        {/*one anarchy at a time.*/}
                        {/*</span>*/}
                        {/*            <div*/}
                        {/*                className="h-1 ml-auto duration-300 origin-left transform bg-red-accent-400 scale-x-30 group-hover:scale-x-100"/>*/}
                        {/*        </h2>*/}

                        <p className="text-gray-700 lg:text-sm lg:max-w-md">
                            "I think happiness is a boring category. I don't like people who are happy. Happy people are usually stupid or at least boring. They are like that guy in the joke who is asked in a coffee shop, 'Coffee or tea?' and answers, 'Yes, please.' Only a happy idiot answers that way. But I would like to meet a person who, when asked 'Coffee or tea?' responds, 'No, thank you." <div
                            className={"text-gray-700 font-bold"}>Slavoj Žižek</div>
                        </p>
                    </div>

                    <div className="grid gap-6 row-gap-5 mb-8 lg:grid-cols-3 sm:row-gap-6 sm:grid-cols-2">


                        {projects.map((project: ProjectData) => (
                            <ProjectCard
                                key={project.id}
                                id={project.id}
                                title={project.title}
                                description={project.description}
                                image={process.env.REACT_APP_API_URL + project.image}
                                images={project.images}
                                onClick={handleProjectClick}
                                emoji={project.emoji}
                                created_at={project.created_at}
                                author={project.author}
                                category={project.category}
                                video={project.video}
                            />
                        ))}


                    </div>
                </div>


            )}
        </div>
    );
};

export default Projects;
